import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@pulse/ui/components';
import { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import tw from '~/tailwind.css?url';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: tw }];

export const loader = ({ request }: LoaderFunctionArgs) => {
  const userAgent = request.headers.get('User-Agent');

  return { userAgent };
};

function App() {
  const { userAgent } = useLoaderData<typeof loader>();

  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content={`width=device-width, initial-scale=1${
            // This is a fix for iOS Safari zooming in on input focus
            // https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming#bootstrap-default-input-font-sizing-1rem
            userAgent
              ? userAgent.includes('iPhone') || userAgent.includes('iPad')
                ? ', maximum-scale=1'
                : ''
              : ''
          }`}
        />
        <Meta />
        <Links />
      </head>
      <body className='min-h-screen w-full antialiased bg-background bg-gradient-to-t from-primary/10 to-background to-50%'>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === 'production' && (
          <>
            <script
              async
              src='https://www.googletagmanager.com/gtag/js?id=G-H9L574WPYT'
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'G-H9L574WPYT');
              `,
              }}
            ></script>
          </>
        )}
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body className='min-h-screen w-full antialiased bg-background bg-gradient-to-t from-primary/10 to-background to-50%'>
        <div className='flex items-center min-h-screen justify-center w-full'>
          <div className='container'>
            <Card className='max-w-sm mx-auto'>
              <CardHeader>
                <CardTitle>Oops something went wrong!</CardTitle>
              </CardHeader>
              <CardContent className='space-y-2 text-sm'>
                <p>
                  Please refresh the page or try again later. If the problem
                  persists, please contact support and provide the error below.
                </p>
                <pre className='text-wrap'>
                  {isRouteErrorResponse(error)
                    ? error.data
                    : error instanceof Error
                    ? error.message
                    : 'Unknown error'}
                </pre>
              </CardContent>
              <CardFooter>
                <Button asChild className='w-full' variant='outline'>
                  <Link to='/'>Back to lookup</Link>
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
